<template>
  <div>
    <header class="page-header">
      <PageTitle/>
    </header>

    <div class="main-content">
      <div class="pane table-pane" :class="{'loading': isLoading}">
        <div class="table-wrapper">
          <div class="table-element">
            <table class="overview" v-if="!isLoading">
              <thead>
              <tr>
                <th class="id">ID</th>
                <th></th>
              </tr>
              </thead>

              <tbody v-if="logs.length > 0">
              <tr :key="i" v-for="(log, i) in logs">
                <td>{{ log.id }}</td>
                <td>{{ log.module }}</td>
                <td>{{ log.message }}</td>
                <td>{{ datetime.fromISO(log.created).setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ total.min }} - {{ total.max }} van {{ totalRows }} {{ 'logs' }}
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="logs.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import PageTitle from '@/components/admin/PageTitle'
import { ApiService } from '@/services/admin/api.service'

export default {
  components: { PageTitle },
  data: function () {
    return {
      datetime: DateTime,
      isLoading: true,
      model: 'logs',
      logs: [],
      totalRows: 0,
      page: 0,
      perPage: 25
    }
  },
  async mounted () {
    this.isLoading = true
    await this.fetchLogs()

    this.perPage = 25

    this.isLoading = false
  },
  methods: {
    async fetchLogs (params = {}) {
      await ApiService.fetchLogs(params).then(res => {
        this.logs = res.data.log_items
        this.totalRows = res.data.total
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        await this.fetchLogs({
          page: this.page - 1
        })
      }
    },
    nextPage: async function () {
      if (this.logs.length === this.perPage) {
        await this.fetchLogs({
          page: this.page + 1
        })
      }
    }
  },
  computed: {
    total: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.totalRows) {
        offsetMax = this.totalRows
      }

      return {
        min: offsetMin,
        max: offsetMax
      }
    }
  }
}
</script>
